<template>
  <section class="tiss-container">
    <Header
      :beneficiary="beneficiary"
      :startGenerateBySelection="startGenerateBySelection"
      :generateBySelection="generateBySelection"
      :selecteds="selecteds"
      @generateExtract="generateExtract"
    />

    <Cards :cards="cards" />

    <OnlendingFilter 
      :profileOptions="profileOptions"
      :ruleOptions="ruleOptions"
      :healthPlanOptions="healthPlanOptions"
      @changeOnlendingFilters="changeOnlendingFilters"
    />

    <Table
      canRemove
      :onlendings="onlendings"
      :loading="loading"
      :generateBySelection="generateBySelection"
      :selecteds="selecteds"
      :selectAll="selectAll"
      :watchAllSelected="watchAllSelected"
      :professionalsList="professionalsList"
      @openOnlendingDetailsModal="openOnlendingDetailsModal"
      @handleAllOnlendingIds="handleAllOnlendingIds"
      @change-select-all="changeSelectAll"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="currentPageClone"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
      />
    </div>
  </section>
</template>

<script>
// import api from '../../api'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  components: {
    Cards: () => import('@/modules/onlending/components/report/Cards.vue'),
    OnlendingFilter: () => import('@/modules/onlending/components/report/Filter.vue'),
    Header: () => import('@/modules/onlending/components/report/Header.vue'),
    Table: () => import('@/modules/onlending/components/report/Table.vue')
  },
  props: {
    reportSelected: Object,
    ruleOptions: Array,
    profileOptions: Array,
    healthPlanOptions: Array,
    getReports: Function,
    currentPage: Number,
    perPage: Number,
    totalRows: Number,
    beneficiary: Object,
    onlendingIds: Array,
    onlendings: Array,
    cards: [Array, Object]
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      generateBySelection: false,
      selecteds: [],
      
      currentPageClone: 1,

      loading: false,
      selectAll: false,
      watchAllSelected: true,
      professionalsList: []
    }
  },
  created(){
    this.api.showClinicProfessionals(this.clinic.id).then(({data}) => {
      if(data?.length) this.professionalsList = data;
    })
  },
  methods: {
    parseNumberToMoney,
    changeSelectAll(val){
      this.selectAll = val
      this.watchAllSelected = !val
    }, 

    changeOnlendingFilters(filter) {
      this.$emit('changeOnlendingFilter', filter)
      this.currentPageClone = 1
    },

    startGenerateBySelection(){
      this.generateBySelection = true
      this.selecteds = []
    },
    generateExtract(props) {
      this.$emit('generateExtract', props)
    },
    handleAllOnlendingIds(val){
      this.selecteds = val ? this.onlendingIds : []
    },
    openOnlendingDetailsModal(onlending){
      this.$emit('openOnlendingDetailsModal', onlending)
    }
  },
  watch: {
    currentPageClone: {
      handler(newVal) {
        this.$emit('changeCurrentPage', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tiss-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid var( --neutral-300);
  border-radius: 8px;
  margin-bottom: 30px;
}
</style>
